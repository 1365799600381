.refreshContainer {
    position: absolute;
    background: rgba(0, 0, 0, 0.3);
    width: 100vw;
    height: 100vh;
    z-index: 999;
}
.refreshSubContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.refreshElement{
    width: 50px;
    height: 50px;
}